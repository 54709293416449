<template>
  <div class="merchants p-tb-10">
    <PageHeader :border="false" title="产品详情">
      <el-button
        v-if="RequestType === 2 || (RequestType === 1 && (materialdetaisinfo.Status === 0 || materialdetaisinfo.Status === 3))"
        type="primary"
        size="mini"
        class="m-right-5"
        @click="() => { $router.push({ name: 'productEdit1', params: { idx: materialdetaisinfo.IDX, type: RequestType } }) }"
      >
        <i class="el-icon-edit" />修改</el-button>
      <el-button v-if="RequestType === 1" class="m-right-10" plain size="mini" @click="() => { $router.push({ name: 'ProductList', params: { isCache: 6 }}) }"><i class="el-icon-arrow-left" />返回</el-button>
      <el-button v-if="RequestType === 2" class="m-right-10" plain size="mini" @click="() => { $router.push({ name: 'productInfoList', params: { isCache: 6 } }) }"><i class="el-icon-arrow-left" />返回</el-button>
    </PageHeader>
    <div class="producInfo p-lr-20" :v-loading="editProductLoading">
      <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> 产品信息</div>
      <el-form ref="ruleForm" label-width="83px" label-position="left" :inline="true" :model="materialdetaisinfo" size="mini" class="demo-form-inline">
        <div class="InfoList">
          <el-form-item label="申请日期：" class="eachitem">
            <span>{{ $minCommon.formatDate(new Date(materialdetaisinfo.ApplyDate), 'yyyy-MM-dd') }}</span>
          </el-form-item>
          <el-form-item label="申请部门：" class="eachitem">
            <span>{{ materialdetaisinfo.DepartNames }}</span>
          </el-form-item>
          <el-form-item label="申请人：" class="eachitem">
            <span>{{ materialdetaisinfo.ApplyPersonName }}</span>
          </el-form-item>
          <el-form-item label="填表人：" class="eachitem">
            <span>{{ UserInfo.NickName }}</span>
          </el-form-item>
        </div>
        <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> 产品基本信息</div>
        <div class="InfoList1">
          <ul>
            <el-form-item label="物料名称：" class="eachitem">
              <span>{{ materialdetaisinfo.MaterialName }}</span>
            </el-form-item>
            <el-form-item label="物料简称：" class="eachitem">
              <span>{{ materialdetaisinfo.NickName }}</span>
            </el-form-item>
            <el-form-item label="物料编号：" class="eachitem">
              <span>{{ materialdetaisinfo.InvCode }}</span>
            </el-form-item>
            <el-form-item label="物料类型：" class="eachitem">
              <span>{{ materialdetaisinfo.MaterialTypeName }}</span>/<span>{{ materialdetaisinfo.MaterialSubTypeName }}</span>
            </el-form-item>
          </ul>
          <ul>
            <el-form-item label="物料分类：" class="eachitem">
              <span>{{ materialdetaisinfo.InvTypeName }}</span>
            </el-form-item>
            <el-form-item label="品牌：" class="eachitem">
              <span>{{ materialdetaisinfo.BrandName }}</span>
            </el-form-item>
            <el-form-item label="原产地：" class="eachitem">
              <span>{{ materialdetaisinfo.SourceAreaName }}</span>
            </el-form-item>
            <el-form-item label="规格/型号：" class="eachitem">
              <span>{{ materialdetaisinfo.Specification }}</span>
            </el-form-item>
          </ul>
          <ul>
            <el-form-item label="最小包装：" class="eachitem">
              <span>{{ materialdetaisinfo.MinPacking }}</span>
            </el-form-item>
            <el-form-item label="存货代码：" class="eachitem">
              <span>{{ materialdetaisinfo.StorageNo }}</span>
            </el-form-item>
            <el-form-item label="单位：" class="eachitem">
              <span>{{ materialdetaisinfo.StockUnitN }}</span>
            </el-form-item>
            <el-form-item label="产品有效期：" class="eachitem">
              <span v-if="materialdetaisinfo.ShelfLifeType === 0">长期</span>
              <span v-if="materialdetaisinfo.ShelfLifeType > 0">{{ RequestType === 1 ? materialdetaisinfo.ShelfLifeNum : materialdetaisinfo.ShelfLifeNums }} {{ getShefType(materialdetaisinfo.ShelfLifeType).text }}</span>
            </el-form-item>
          </ul>
          <ul>
            <el-form-item label="运输条件：" class="eachitem">
              <span>{{ materialdetaisinfo.TransportConditionName }}</span>
            </el-form-item>
            <el-form-item label="原厂编号：" class="eachitem">
              <span>{{ materialdetaisinfo.ManuPartNum }}</span>
            </el-form-item>
          </ul>
        </div>
      </el-form>
      <el-form ref="ruleForm" abel-position="left" :inline="true" :model="materialdetaisinfo" class="demo-form-inline" size="mini">
        <div class="InfoList2">
          <ul>
            <el-form-item label="注册证号/批准文号/备案凭证号：" class="eachitem2">
              <span>{{ materialdetaisinfo.RegisterNo }}</span>
            </el-form-item>
            <el-form-item label="注册证起始日期：" class="eachitem2">
              <span>
                <!-- {{ materialdetaisinfo.RegisterStart }} -->
                {{ (materialdetaisinfo.RegisterStart !== null && materialdetaisinfo.RegisterStart.length > 0) ? $minCommon.formatDate(new Date(materialdetaisinfo.RegisterStart), 'yyyy-MM-dd') : '' }}
              </span>
            </el-form-item>
          </ul>
          <ul>
            <el-form-item label="注册证截止日期：" class="eachitem2">
              <span>
                <!-- {{ materialdetaisinfo.RegisterCardType === 0? '长期' : materialdetaisinfo.RegisterEnd }} -->
                {{ (materialdetaisinfo.RegisterEnd !== null && materialdetaisinfo.RegisterEnd.length > 0) ? $minCommon.formatDate(new Date(materialdetaisinfo.RegisterEnd), 'yyyy-MM-dd') : '' }}
              </span>
            </el-form-item>

            <el-form-item label="医疗器械注册人：" class="eachitem2">
              <span>{{ materialdetaisinfo.RegisterPerson }}</span>
            </el-form-item>
          </ul>
          <ul>
            <el-form-item label="生产厂家：" class="eachitem2">
              <span>{{ materialdetaisinfo.ProductFactory }}</span>
            </el-form-item>

            <el-form-item label="药品上市持有许可人：" class="eachitem2">
              <span>{{ materialdetaisinfo.MaterialPermissionPerson }}</span>
            </el-form-item>
          </ul>
          <ul>
            <el-form-item label="生产企业许可证号：" class="eachitem2">
              <span>{{ materialdetaisinfo.ProductLicenseNo }}</span>
            </el-form-item>

            <el-form-item label="物料默认所在仓库：" class="eachitem2">
              <span>{{ materialdetaisinfo.StockCode }}</span>
            </el-form-item>
          </ul>
          <ul>
            <el-form-item label="储存条件：" class="eachitem2">
              <span>{{ materialdetaisinfo.StorageConditionName }}</span>
            </el-form-item>
            <el-form-item label="DI码：" class="eachitem2">
              <span>{{ materialdetaisinfo.DINum }}</span>
            </el-form-item>
          </ul>
          <ul>
            <el-form-item label="备注：" class="eachitem2">
              <span>{{ materialdetaisinfo.MaterialRemark }}</span>
            </el-form-item>
          </ul>
        </div>

        <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> 财务信息</div>
        <div class="InfoList">
          <el-form-item label="产品税率(%)" size="mini" class="eachitem">
            {{ getTaxInfo(materialdetaisinfo.Tax) }}
          </el-form-item>
          <el-form-item label="计划价格(元)" size="mini" prop="TaxRate" class="eachitem">
            <span>{{ materialdetaisinfo.PlanPrice }}</span>
          </el-form-item>
          <el-form-item label="发布价格(元)" size="mini" prop="TaxRate" class="eachitem">
            <span>{{ materialdetaisinfo.Price }}</span>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import materialDetailsInfo from '@/minxin/product/materialDetailsInfo.js'
export default materialDetailsInfo
</script>
<style scoped lang='scss'>
@import '@/style/index.scss';
@import '@/style/search.scss';
@import '@/style/product.scss';
</style>
<style lang='scss'>
</style>


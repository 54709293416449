import $post from '@/request/productApis.js'
import PageHeader from '@/components/PageHeader.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import $comjs from '@/minxin/commonJs.js'
const materialCertificatesInfo = {
  name: 'materialCertificatesInfo',
  components: {
    PageHeader,
    ElImageViewer
  },
  props: {
    IDX: {
      type: Number,
      default: 0
    }, // 产品id
    RequestType: {
      type: Number,
      default: 0
    } // 1： 首营  2：列表
  },
  data() {
    return {
      ProductfilelistLoading: false,
      materialdetaisinfo: {
        MaterialName: '',
        NickName: '',
        InvCode: '',
        MaterialTypeName: '',
        InvTypeName: '',
        BrandID: '',
        Specification: '',
        StockUnitN: '',
        MinPacking: '',
        RegisterNo: '',
        RegisterStart: '',
        RegisterEnd: '',
        ProductFactory: '',
        ShelfLifeNum: '',
        ShelfLifeTypeStr: '',
        TransportCondition: '',
        ProductLicenseNo: '',
        SourceAreaName: '',
        StorageNo: '',
        ApproveState: null,
        MaterialCertificateList: [{
          IDX: '主键',
          CertificateType: '证件类别的 Code的值',
          CertificateTypeName: '产品证照名称',
          DatetimeType: '证照是否是长期有效期状态 0：否，1：是',
          CertificateStartLine: '证照起始日期',
          CertificateDeadLine: '证照结束日期',
          CertificatePath: '证相路径',
          CertificateName: '注册证号',
          CertificateCompanyName: '注册证企业名称'
        }]
      },
      materialFiles: [],
      showViewer: false, // 查看图片
      MaterialFileList: []
    }
  },
  created() {
  },
  mounted() {
    if (this.IDX && this.IDX > 0) {
      this.getProductDetaisInfo(this.IDX)
    }
  },
  methods: {
    // 单个下载证照
    downonefile(_row) {
      const _this = this
      _this.editProductLoading = true
      // 截取文件后缀
      const _filename = _row.CertificatePath.substring(_row.CertificatePath.lastIndexOf('.'))
      $post.GetFirstCampFiles({ FileURL: this.b2bstaticUrl + _row.CertificatePath }).then((res) => {
        _this.editProductLoading = false
        if (res.RetCode === '0') {
          $comjs.downloadFile(res.Message, $comjs.guid() + _filename)
        }
      })
    },
    viewPic() {
      this.materialdetaisinfo.MaterialCertificateList.forEach(item => {
        this.MaterialFileList.push(this.b2bstaticUrl + item.CertificatePath)
      })
      this.showViewer = true
    },
    // 关闭预览
    showViewerClose() {
      this.showViewer = false
    },
    getProductDetaisInfo(_idx) {
      const _this = this
      _this.ProductfilelistLoading = true
      if (_this.RequestType === 1) {
        $post.getProductInfo({ FCMIdx: _idx }).then(res => {
          _this.ProductfilelistLoading = false
          console.log(res)
          if (res.RetCode === '0') {
            _this.materialdetaisinfo = res.Message
          } else {
            _this.$message.error('获取产品信息出错： ' + res.RetMsg)
            _this.$router.push({ name: 'ProductList1' })
          }
        })
      } else if (_this.RequestType === 2) {
        $post.GetMaterialRefCompanyDeatil({ FCMIdx: _idx }).then(res => {
          _this.ProductfilelistLoading = false
          if (res.RetCode === '0') {
            _this.materialdetaisinfo = res.Message
          } else {
            _this.$message.error('获取产品信息出错： ' + res.RetMsg)
            _this.$router.push({ name: 'productInfoList1' })
          }
        })
      }
    },
    // 单个删除证照
    confirmdeleteonefile(_idx) {
      const _this = this
      _this.$confirm('确认删除证照吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.DelteCertifi(_idx)
      }).catch(() => {
      })
    },
    DelteCertifi(_idx) {
      const _this = this
      _this.editProductLoading = true
      let _Idxs = ''
      if (_idx > 0) {
        _Idxs = _idx
      } else {
        _Idxs = _this.checkedcetificasidx.join(',')
      }
      $post.DeleAddMaterCertificateInfo({ Idx: _Idxs, FirstCamp_MaterialInfoID: _this.materialdetaisinfo.IDX }).then((res) => {
        _this.editProductLoading = false
        if (res.RetCode === '0') {
          this.getProductDetaisInfo(_this.materialdetaisinfo.IDX)
          _this.$message('操作成功')
          this.updateOrderRemarkVisibel = false
        } else {
          _this.$message('操作失败：' + res.RetMsg)
        }
      })
    },
    // 单个删除证照
    confirmdeleteonefile1(_idx) {
      const _this = this
      _this.$confirm('确认删除证照吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.DelteCertifi1(_idx)
      }).catch(() => {
      })
    },
    DelteCertifi1(_idx) {
      const _this = this
      _this.editProductLoading = true
      let _Idxs = ''
      if (_idx > 0) {
        _Idxs = _idx
      } else {
        _Idxs = _this.checkedcetificasidx.join(',')
      }
      $post.DeleMaterCertificateInfo({ Idx: _Idxs, FirstCamp_MaterialInfoID: _this.materialdetaisinfo.IDX }).then((res) => {
        _this.editProductLoading = false
        if (res.RetCode === '0') {
          this.getProductDetaisInfo(_this.materialdetaisinfo.IDX)
          _this.$message('操作成功')
          this.updateOrderRemarkVisibel = false
        } else {
          _this.$message('操作失败：' + res.RetMsg)
        }
      })
    }
  }
}
export default materialCertificatesInfo

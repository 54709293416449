<template>
    <div class="steps">
        <div class="step" v-for="(item, index) in headerSteps" :key="index">
            <div :class="item.isApproed === 3 ? 'icon icon3':'icon icon2'">
                <i  :class="item.step_Icon"  v-if="item.isApproed === 3" />
                <el-button  size="mini" v-if="item.isApproed === 1" type="success" icon="el-icon-check" circle></el-button>
                <el-button  size="mini" v-if="item.isApproed === 2" type="danger" icon="el-icon-close" circle></el-button>
                </div>
            <div class="text">
                <span class="text_step" >{{item.step}}</span>
                <br/>
                <span class="text_text">{{item.step_text}}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    headerSteps: {
      type: Array,
      default: () => [{
        step: '00001',
        step_Icon: 'el-icon-edit',
        step_text: '审批编号',
        isApproed: 3
      },
      {
        step: '已通过',
        step_Icon: 'el-icon-circle-check',
        step_text: '流程状态',
        isApproed: 1 // 1 是 2 不是 3不显示
      },
      {
        step: '产品首营审批',
        step_Icon: 'el-icon-tickets',
        step_text: '审批类型',
        isApproed: 3
      },
      {
        step: '采购部',
        step_Icon: 'el-icon-set-up',
        step_text: '申请部门',
        isApproed: 3
      },
      {
        step: '蝎子奶奶',
        step_Icon: 'el-icon-user',
        step_text: '申请人',
        isApproed: 3
      },
      {
        step: '2020-01-21',
        step_Icon: 'el-icon-date',
        step_text: '发起时间',
        isApproed: 3
      }]
    }
  },
  data() {
    return {
    }
  }
}
</script>
<style lang='scss'>
.steps .el-button--mini.is-circle {
    padding: 6px;
}
.steps {
    height: 100px;
    width:100%
}
/*.step {
    border:1px solid #e4e4e6;
    float: left;
    width: calc(100%*0.1652);
}*/
.text_step {
    font-size: 13px;
    font-weight: bold;
}
.text_text {
    font-size: 8px;
    color: #b9b9c3;
}
.icon {
    float: left;
    font-size: 25px;
    width: 50px;
    margin-left: 15%;
}
.icon2 {
    line-height: 32px;
}
.icon3 {
    line-height: 40px;
}
.text {
    float: left;
    width: calc(100%-50);
}
</style>

<template>
  <div class="merchants">
    <materialDetailsInfo ref="materialDetailsInfo" :i-d-x="IDX" :request-type="requestType" />
    <!--  首营的证照列表 -->
    <materialCertificatesInfo v-if="requestType === 1" :i-d-x="IDX" :request-type="requestType" />
    <!-- 已审批通过的证照列表  -->
    <productCertificateList v-if="requestType === 2" :i-d-x="IDX" :request-type="requestType" />
  </div>
</template>
<script>
import ProductApprove from '@/minxin/product/ProductApprove.js'
export default ProductApprove
</script>
<style scoped lang='scss'>
@import '@/style/index.scss';
@import '@/style/search.scss';
@import '@/style/product.scss';
</style>
<style lang='scss'>
</style>


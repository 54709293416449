import $post from '@/request/productApis.js'
import detailsHeader from '@/components/detaisHeader.vue'
import PageHeader from '@/components/PageHeader.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import $comjs from '@/minxin/commonJs.js'
import materialDetailsInfo from '@/views/Product/materialDetailsInfo.vue'
import materialCertificatesInfo from '@/views/Product/materialCertificatesInfo.vue'
import productCertificateList from '@/views/Product/productCertificateList.vue'
const ProductApprove = {
  name: 'ProductApprove',
  components: {
    detailsHeader,
    PageHeader,
    ElImageViewer,
    materialDetailsInfo,
    materialCertificatesInfo,
    productCertificateList
  },
  data() {
    return {
      IDX: 0,
      IsEdit: false,
      UserInfo: {}, // 人员信息
      materialdetaisinfo: {
        MaterialName: '',
        NickName: '',
        InvCode: '',
        MaterialTypeName: '',
        InvTypeName: '',
        BrandID: '',
        Specification: '',
        StockUnitN: '',
        MinPacking: '',
        RegisterNo: '',
        RegisterStart: '',
        RegisterEnd: '',
        ProductFactory: '',
        ShelfLifeNum: '',
        ShelfLifeTypeStr: '',
        TransportCondition: '',
        ProductLicenseNo: '',
        SourceAreaName: '',
        StorageNo: '',
        ApproveState: null,
        MaterialCertificateList: [{
          IDX: '主键',
          CertificateType: '证件类别的 Code的值',
          CertificateTypeName: '产品证照名称',
          DatetimeType: '证照是否是长期有效期状态 0：否，1：是',
          CertificateStartLine: '证照起始日期',
          CertificateDeadLine: '证照结束日期',
          CertificatePath: '证相路径',
          CertificateName: '注册证号',
          CertificateCompanyName: '注册证企业名称'
        }]
      },
      materialFiles: [],
      showViewer: false, // 查看图片
      MaterialFileList: [],
      requestType: 0,
      stauts: -1
    }
  },
  created() {
    this.IDX = parseInt(this.$route.params.idx)
    // 1 首营 2：列表
    this.requestType = parseInt(this.$route.params.type)
    // this.getUserinfo()
  },
  mounted() {
  },
  destroyed() {
    // this.stauts = this.$refs.materialDetailsInfo.Status
  },
  methods: {
    // 单个下载证照
    downonefile(_row) {
      const _this = this
      _this.editProductLoading = true
      // 截取文件后缀
      const _filename = _row.CertificatePath.substring(_row.CertificatePath.lastIndexOf('.'))
      $post.GetFirstCampFiles({ FileURL: this.b2bstaticUrl + _row.CertificatePath }).then((res) => {
        _this.editProductLoading = false
        if (res.RetCode === '0') {
          $comjs.downloadFile(res.Message, $comjs.guid() + _filename)
        }
      })
    },
    viewPic() {
      this.materialdetaisinfo.MaterialCertificateList.forEach(item => {
        this.MaterialFileList.push(this.b2bstaticUrl + item.CertificatePath)
      })
      this.showViewer = true
    },
    // 关闭预览
    showViewerClose() {
      this.showViewer = false
    },
    // 获取人员信息
    async getUserinfo() {
      const _this = this
      _this.editProductLoading = true
      await $post.getUserInfo().then((res) => {
        _this.editProductLoading = false
        if (res.RetCode === '0') {
          _this.UserInfo = res.Message
        }
      })
    },
    getProductDetaisInfo(_idx) {
      const _this = this
      $post.getProductInfo({ FCMIdx: _idx }).then(res => {
        console.log(res)
        if (res.RetCode === '0') {
          _this.materialdetaisinfo = res.Message
        }
      })
    }
  }
}
export default ProductApprove

<template>
  <div class="merchants">
    <div class="t_line_s ZizhiwenjianBox">
      <div class="main_flex flex j-between">
        <div class="left min-border-right flex j-top">
          <PageHeader title="资质文件" />
          <div v-if="materialdetaisinfo.MaterialCertificateList === null || materialdetaisinfo.MaterialCertificateList.length < 1" class="uploaddiv">
            <div class="el-upload__text">
              暂无数据</div>
          </div>
          <div class="left_list">
            <div v-for="(item,index) in materialdetaisinfo.MaterialCertificateList" :key="index" class="img_view">
              <img :src="(b2bstaticUrl + '/'+item.CertificatePath)" @click="viewPic">
            </div>
          </div>
        </div>
        <div class="right">
          <PageHeader class="b_line_s" :border="false" title="已上传的文件" />
          <div class="p-lr-20 p-top-20">
            <el-table
              v-loading="ProductfilelistLoading"
              :border="false"
              max-height="300"
              width="100%"
              :data="materialdetaisinfo.MaterialCertificateList"
            >
              <el-table-column
                prop="CertificateTypeName"
                label="证件类型"
                width="180"
              />
              <el-table-column
                prop="CertificateCompanyName"
                label="企业名称"
                width="180"
              />
              <el-table-column
                prop="CertificateName"
                label="证件编号"
              />
              <el-table-column
                prop="TypeString"
                label="状态"
              />
              <el-table-column
                prop="CertificateDeadLine"
                label="证件有效期"
              />
              <el-table-column
                label="操作"
                width="50"
              >
                <template slot-scope="scope">
                  <el-popover
                    placement="left"
                    trigger="hover"
                    :width="50"
                    popper-class="test_pop_view"
                  >
                    <div>
                      <!-- <div class="min-border-bottom m-tb-10">
                          <i class="el-icon-edit p-right-10" />
                          <span><el-link type="primary" :underline="false" @click="edtiFile(scope.row)">编辑</el-link></span>
                        </div>
                        <div class="m-tb-10">
                          <i class="el-icon-download p-right-10" />
                          <span><el-link type="primary" :underline="false" @click="confirmdeleteonefile1(scope.row.IDX)">删除</el-link></span>
                        </div> -->
                      <div class="m-tb-10">
                        <i class="el-icon-download p-right-10" />
                        <span><el-link type="primary" :underline="false" @click="downonefile(scope.row)">下载</el-link></span>
                      </div>
                    </div>
                    <i slot="reference" class="el-icon-more" />
                  </el-popover>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <el-image-viewer v-if="showViewer" :url-list="MaterialFileList" :on-close="showViewerClose" />
  </div>
</template>
<script>
import materialCertificatesInfo from '@/minxin/product/materialCertificatesInfo.js'
export default materialCertificatesInfo
</script>
<style scoped lang='scss'>
@import '@/style/index.scss';
@import '@/style/search.scss';
@import '@/style/product.scss';
</style>

